<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>환전</sub-title>

                <div class="main_panel">
                    <div class="re_info">
                        <span>
                            <strong><i class="fa fa-star" aria-hidden="true"></i></strong>
                             ★출금시 최소1만원부터 만원단위만 가능합니다.
                        </span>
                        <span>
                            <strong><i class="fa fa-star" aria-hidden="true"></i></strong>
                             ★최대환전금액은 "충/환전 규정"에서 확인바랍니다.
                        </span>
                        <span>
                            <strong><i class="fa fa-star" aria-hidden="true"></i></strong>
                            ★고액출금시 보안상 나눠서 출금처리됩니다.
                        </span>
                        <span>
                            <strong><i class="fa fa-star" aria-hidden="true"></i></strong>
                             ★가입시 등록된 계좌번호만 출금처리가 가능합니다.
                        </span>
                        <span>
                            <strong><i class="fa fa-star" aria-hidden="true"></i></strong>
                            ★2시간마다 레벨별한도에 맞게 환전처리 가능합니다.
                        </span>
                        <span>
                            <strong><i class="fa fa-star" aria-hidden="true"></i></strong>
                             ★"포인트롤링 규정"을 완료해야 환천처리 가능합니다.
                        </span>
                    </div>
                    <div class="re_cont">
                        <div class="c1">
                            예금명
                        </div>
                        <div class="c2" style="">
                            <span>{{$store.state.userInfo.beneficiary}}</span>
                        </div>
                    </div>
                    <div class="re_cont">
                        <div class="c1">
                            금액
                        </div>
                        <div class="c2" style="">
                            <div style="display: flex;justify-content: flex-start;align-items: center;padding: 10px 0">
                                <input type="text" v-model="applyCash.amount" class="re_input" placeholder="충전금액"
                                       style="">
                                <button type="button" class="btn-re" @click="changeMoney(0)" style="width: 130px;margin-left: 6px">정정하기</button>
                            </div>
                            <div style="display: flex; justify-content: flex-start;width: 100%;padding: 10px 0;flex-wrap: wrap">
                                <button type="button" class="btn-re" @click="changeMoney(10000)">
                                    1만원
                                </button>
                                <button type="button" class="btn-re" @click="changeMoney(30000)">
                                    3만원
                                </button>
                                <button type="button" class="btn-re" @click="changeMoney(50000)">
                                    5만원
                                </button>
                                <button type="button" class="btn-re" @click="changeMoney(100000)">
                                    10만원
                                </button>
                                <button type="button" class="btn-re" @click="changeMoney(500000)">
                                    50만원
                                </button>
                                <button type="button" class="btn-re" @click="changeMoney(1000000)">
                                    100만원
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="rec_buttons" style="">
                        <button class="b1" @click="exchange">환전신청
                        </button>
                    </div>
                </div>
                <div class="re_history">
                    <table cellpadding="0" cellspacing="0" border="0">
                        <colgroup>
                            <col width="27%">
                            <col width="27%">
                            <col width="27%">
                            <col width="15%">
                        </colgroup>
                        <thead>
                        <tr>
                            <th>일자</th>
                            <th>금액</th>
                            <th @click="refreshData()" style="cursor: pointer">상태 <i class="fa fa-refresh"
                                                                                     aria-hidden="true"></i>
                            </th>
                            <th>-</th>
                        </tr>
                        <tr v-for="(item,indx) in exchangeList" :key="indx">
                            <td>{{item.createTime|datef('MM/DD HH:mm')}}</td>
                            <td>{{item.amount|comma}}</td>
                            <td>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_WAITING">미확인</span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_CHECKING">처리중</span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_COMPLITE"><i class="fa fa-check"
                                                                                                  aria-hidden="true"></i></span>
                                <span v-if="item.status === sportsConst.APPLY_STATUS_CANCEL"
                                      style="color: red">실패</span>
                            </td>
                            <td>
                  <span
                          style="display: inline-block;width: 60px; height: 20px; line-height: 20px;text-align: center;cursor: pointer"
                          v-if="item.status !== sportsConst.APPLY_STATUS_WAITING && item.status
                        !== sportsConst.APPLY_STATUS_CHECKING" @click="deleteById(item.id)">
                     <i class="fa fa-close"></i> 삭제
                  </span>

                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>



</template>

<script>
    import SubTitle from "../../components/SubTitle";
    import RightBarComp from "../../components/RightBarComp";
    import {deleteApply, exchangeCash, getApplyCashList, rechargeCash} from "../../network/userRequest";
    import sportsConst from "../../common/sportsConst";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import TopbarComp from "../../components/TopbarComp";
    import FootComp from "../../components/FootComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import {getAccountBalance, subjectMemberCasinoCash} from "../../network/casinoHonorRequest";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";

    export default {
        name: "Exchange",
        mixins:[postionMixin],
        components: {
            SportsBetCartComp,
            RightButtonsComp,
            UserInfoComp,
            SportsLeftBarComp,
            RightBarBannerComp, FootComp, TopbarComp, LeisureLeftComp, LeftBarComp, RightBarComp, SubTitle},
        data() {
            return {
                sportsConst,
                applyCash: {
                    amount: 0,
                    extractPasswd: ''
                },
                search: {applyType: sportsConst.APPLY_TYPE_EXCHANGE},
                exchangeList: [],
                casinocash: 0,
                position:"출금",
            }
        },
        methods: {
            changeMoney(cash) {
                if (cash === 0) {
                    this.applyCash.amount = 0;
                } else {
                    this.applyCash.amount += cash;
                }
            },
            exchange() {

                this.$swal({
                    title: this.applyCash.amount + '원 환전신청 하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        this.$store.commit(RECEIVE_SHOW_LOADING)
                        exchangeCash(this.applyCash).then(res => {
                            this.$store.commit(RECEIVE_HIDE_LOADING)
                            if (res.data.success) {
                                this.$swal({
                                    title: '환전신청성공',
                                    type: 'success',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                                this.$store.dispatch('actionUserInfo')
                                this.initExchangeList()
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            change2sportsmoney(){
                this.$swal({
                    title: '스포츠머니로 전환 하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    this.betInfo = {selectedVals: []}
                    if (res.value) {
                        this.$store.commit(RECEIVE_SHOW_LOADING)
                        subjectMemberCasinoCash().then((res)=>{
                            this.$store.commit(RECEIVE_HIDE_LOADING)
                            this.$swal({
                                title: '전환성공',
                                type: 'success',
                                showCancelButton: false,
                                showConfirmButton: true
                            })
                            this.casinocash = 0;
                            this.$store.dispatch('actionUserInfo')
                            setTimeout(()=>{
                                this.getCasinoBalance();
                            },1300)
                        })
                    }
                })

            },
            initExchangeList() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getApplyCashList(this.search).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.exchangeList = res.data.data
                    }
                })
            },
            refreshData() {
                this.initExchangeList()
                this.$bus.$emit('applyRequestSuccess')
            },
            deleteById(id) {
                this.$swal({
                    title: '삭제하시겠습니까?',
                    type: 'question',
                    confirmButtonText: ' 예 ',
                    cancelButtonText: '아니오'
                }).then((res) => {
                    if (res.value) {
                        deleteApply(id).then(res => {
                            if (res.data.success) {
                                this.initExchangeList()
                            } else {
                                this.$swal({
                                    title: res.data.msg,
                                    type: 'error',
                                    showCancelButton: false,
                                    showConfirmButton: true
                                })
                            }
                        })
                    }
                })
            },
            getCasinoBalance(){
                getAccountBalance().then(res => {
                    this.$set(this.$store.state.userInfo, 'casinocash', res.data.data)
                })
            }
        },
        created() {
            this.initExchangeList()
        },
        mounted() {
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/reex.css");
</style>